import React from "react";

import dataProfile from "../../dummy/profile";

export default function Profile() {
  const [imgSource, setImgSource] = React.useState(dataProfile?.img);

  return (
    <div className="sm:flex text-center sm:text-left">
      <div
        style={{
          backgroundColor: "#FF3CAC",
          backgroundImage:
            "-webkit-linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)",
        }}
        className="md:hidden h-36 rounded-xl relative"
        data-aos="fade-up"
      >
        <span className="font-mono absolute left-4 top-2">꧋ꦲꦭꦺꦴ👋</span>
      </div>
      <img
        data-aos="flip-right"
        src={imgSource}
        alt="img-profile"
        className="mt-[-70px] md:mt-0 rounded-full h-32 w-32 object-cover border-4 backdrop-blur-md hover:bg-yellow-400 mx-auto sm:mx-0 hover:p-4 hover:border-[#996611] cursor-none"
        onMouseEnter={() => {
          setImgSource(dataProfile?.hover?.img);
        }}
        onMouseLeave={() => {
          setImgSource(dataProfile?.img);
        }}
        onFocusCapture={() => {
          console.log("focus");
        }}
      />
      <div
        className="flex items-center justify-center mt-4 sm:mt-0"
        data-aos="fade-down"
      >
        <div className="md:pl-4">
          <div className="font-mono text-4xl flex items-center gap-2">
            {dataProfile.name}{" "}
            {/* <img src={Verified} alt="img-verified" className="h-10 w-10" /> */}
          </div>
          <div className="font-mono text-xs text-slate-500 hover:text-lime-800">
            {window.location.hostname}
          </div>
          <div>{dataProfile.flag}</div>
          <div className="font-mono text-sm mt-1">{dataProfile.desc}</div>
        </div>
      </div>
    </div>
  );
}
