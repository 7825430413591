import * as React from "react";

export default React.memo(function Card(props) {
  const { children } = props;

  return (
    <div
      className="bg-slate-900 hover:bg-gray-800 border-2 border-blue-900 p-4 rounded block"
      data-aos="zoom-in-up"
    >
      {children}
    </div>
  );
});
