import * as React from "react";
import stc from "string-to-color";
import moment from "moment";

export default function CardBlog({ item }) {
  const openBlog = React.useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const titleConvert = React.useCallback((value) => {
    if (!value) return "";
    const convert = value.split(" ");
    const first = convert.length > 0 ? convert[0] : "";
    const second = convert.length > 1 ? convert[1] : "";

    const result = `${first[0]}${second[0]}`.toLocaleUpperCase();

    return result;
  }, []);

  return (
    <div
      className="flex border-2 border-gray-700 bg-slate-900 rounded overflow-auto"
      data-aos="zoom-in-up"
    >
      {item.cover_image && (
        <img
          src={item.cover_image}
          alt={item.title}
          className="h-30 object-cover w-[20%]"
        />
      )}
      {!item.cover_image && (
        <div
          className="h-30 w-[20%] flex justify-center items-center"
          style={{
            background: `linear-gradient(56deg, ${stc(
              titleConvert(item.title)
            )} 0%, ${stc(item.title)} 100%)`,
          }}
        >
          {titleConvert(item.title)}
        </div>
      )}
      <div className="w-[80%] flex flex-col gap-4 p-4">
        <div>
          <div
            className="font-mono cursor-pointer hover:text-blue-600 w-fit"
            onClick={() => openBlog(item.url)}
          >
            {item.title}
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {item.tag_list.map((tag) => (
            <div
              className="text-sm font-thin text-gray-400 !font-mono"
              key={tag}
            >
              <span style={{ color: stc(tag) }}>#</span>
              {tag}
            </div>
          ))}
        </div>
        <div className="text-sm !font-thin text-gray-400">
          {moment(item.published_at).fromNow()}
        </div>
      </div>
    </div>
  );
}
