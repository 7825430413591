import React from "react";

import img from "../assets/img/super-mario.svg";

export default function Loading({ sx }) {
  return (
    <img
      src={img}
      alt="loading...."
      className="rotateLoading"
      style={{ ...sx }}
    />
  );
}
