import CodeIgniter from "../assets/img/codeigniter.png";
import Dart from "../assets/img/dart.png";
import ExpressJs from "../assets/img/expressjs.png";
import Flask from "../assets/img/flask.jpeg";
import Flutter from "../assets/img/flutter.png";
import Golang from "../assets/img/golang.png";
import Javascript from "../assets/img/javascript.png";
import Laravel from "../assets/img/laravel.png";
import NextJs from "../assets/img/nextjs.png";
import Php from "../assets/img/php.png";
import Python from "../assets/img/python.png";
import ReactJs from "../assets/img/reactjs.png";
import Typescript from "../assets/img/typescript.png";
import Bootstrap from "../assets/img/bootstrap.png";
import Mui from "../assets/img/mui.png";
import Tailwind from "../assets/img/tailwind.png";
import Git from "../assets/img/git.png";
import Github from "../assets/img/github.png";
import MySql from "../assets/img/mysql.png";
import NodeJs from "../assets/img/nodejs.png";

const data = [
  {
    img: Javascript,
    url: "https://www.javascript.com/",
  },
  {
    img: ReactJs,
    url: "https://reactjs.org/",
  },
  {
    img: ExpressJs,
    url: "https://expressjs.com/",
  },
  {
    img: Python,
    url: "https://www.python.org/",
  },
  {
    img: Flask,
    url: "https://flask.palletsprojects.com/en/2.1.x/",
  },
  {
    img: Php,
    url: "https://www.php.net/download-logos.php",
  },
  {
    img: CodeIgniter,
    url: "https://www.codeigniter.com/",
  },
  {
    img: Laravel,
    url: "https://laravel.com/",
  },
  {
    img: Flutter,
    url: "https://flutter.dev/",
  },
  {
    img: Dart,
    url: "https://dart.dev/",
  },
  {
    img: NextJs,
    url: "https://nextjs.org/",
  },
  {
    img: Golang,
    url: "https://go.dev/",
  },
  {
    img: Typescript,
    url: "https://www.typescriptlang.org/",
  },
  {
    img: Bootstrap,
    url: "https://getbootstrap.com/",
  },
  {
    img: Mui,
    url: "https://mui.com/",
  },
  {
    img: Tailwind,
    url: "https://tailwindcss.com/",
  },
  {
    img: Git,
    url: "https://git-scm.com/",
  },
  {
    img: Github,
    url: "https://github.com/",
  },
  {
    img: MySql,
    url: "https://www.mysql.com/",
  },
  {
    img: NodeJs,
    url: "https://nodejs.org/en",
  },
];

export default data;
