const data = [
  {
    title: "University of Technology Yogyakarta",
    subtitle: "Bachelor Degree, Informatics",
    timeStart: "2017-09-01",
    timeEnd: "2021-02-01",
    url: "https://uty.ac.id/",
    docs: [
      {
        title: "",
        url: "http://eprints.uty.ac.id/7361/",
      },
    ],
  },
  {
    title: "SMAN 1 Lubuk Pakam",
    subtitle: "Science",
    timeStart: "2016-07-01",
    timeEnd: "2017-04-01",
    url: "https://smansatulubukpakam.sch.id/",
  },
  {
    title: "SMAN 9 Kendari",
    subtitle: "Science",
    timeStart: "2014-07-01",
    timeEnd: "2016-06-01",
    url: "https://smanegeri9kendari.sch.id/",
  },
  {
    title: "SMP N 2 Kendari",
    timeStart: "2013-09-01",
    timeEnd: "2014-06-01",
  },
  {
    title: "SMP Angkasa Lanud Sultan Hasanuddin",
    timeStart: "2011-07-01",
    timeEnd: "2013-09-27",
  },
  {
    title: "SD N 2 Amasing Kota",
    timeStart: "2007-07-01",
    timeEnd: "2011-06-28",
  },
  {
    title: "SD Inpres Pai I",
    timeStart: "2006-07-01",
    timeEnd: "2007-06-28",
  },
  {
    title: "TK Islam Sudiang Asri",
    timeStart: "2006-01-01",
    timeEnd: "2006-06-28",
  },
];

export default data;
