import { AiFillGithub } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";

export default function CardPortfolio({ data, idx }) {
  return (
    <div
      className="border-2 rounded border-blue-900 bg-slate-800 hover:bg-transparent p-2"
      data-aos="fade-up"
      data-aos-delay={`${idx}00`}
    >
      <img
        src={data.img}
        alt={`portfolio-${idx}`}
        className="h-28 w-full object-cover rounded"
      />
      <div>
        <div className="mt-2 font-mono text-base text-slate-300">
          {data.title}
        </div>
        <div className="flex mt-3 items-center">
          {data.github && (
            <AiFillGithub
              size="25"
              className="cursor-pointer hover:text-lime-500 mr-2"
              onClick={() => window.open(data.github)}
            />
          )}

          {data.demo && (
            <IoMdLink
              size="25"
              className="cursor-pointer hover:text-lime-500"
              onClick={() => window.open(data.demo)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
