import pWaysFood from "../assets/img/portfolio-waysfood.png";
import pMySosmed from "../assets/img/portfolio-my-sosmed.png";
import pIQuiz from "../assets/img/portfolio-iquiz.png";
import pReboisasi from "../assets/img/portfolio-reboisasi.gif";
import pKoleksiQu from "../assets/img/portfolio-koleksiqu.gif";
import pQuranKu from "../assets/img/portfolio-quranku.gif";

const data = [
  {
    title: "KoleksiQu",
    img: pKoleksiQu,
    demo: "https://koleksi-qu.vercel.app/",
    github: null,
  },
  {
    title: "Quran-Ku",
    // img: "https://raw.githubusercontent.com/Jody-septiawan/quran-ku/main/image-v3.png",
    img: pQuranKu,
    demo: "https://quran-ku-eosin.vercel.app/",
    github: "https://github.com/Jody-septiawan/quran-ku",
  },
  {
    title: "Reboisasi",
    img: pReboisasi,
    demo: "https://reboisasi.netlify.app/",
    github: "https://github.com/Jody-septiawan/reboisasi",
  },
  {
    title: "WaysFood",
    img: pWaysFood,
    demo: null,
    github: "https://github.com/Jody-septiawan/waysfood",
  },
  {
    title: "My-Sosmed",
    img: pMySosmed,
    demo: "https://my-sosmed.vercel.app/",
    github: null,
  },
  {
    title: "iQuiz",
    img: pIQuiz,
    demo: null,
    github: "https://github.com/Jody-septiawan/iQuiz",
  },
];

export default data;
