import * as React from "react";
import { API } from "../apis/microgen";
import toast from "react-hot-toast";

const defaultValue = {
  name: null,
  email: null,
  description: null,
  submit: null,
};

export const useRequestLabs = ({ handleClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(defaultValue);
  const [error, setError] = React.useState(defaultValue);

  const handleChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;

      setError((prev) => {
        return {
          ...prev,
          [name]: null,
        };
      });

      setForm({
        ...form,
        [name]: value,
      });
    },
    [form]
  );

  const validation = React.useCallback((form) => {
    let errorCount = 0;

    // Name field
    const name = form.name;
    const nameEmpty = name?.replace(/\s/g, "");
    if (!name || nameEmpty === "") {
      setError((prev) => {
        return {
          ...prev,
          name: "The 'Name' field is required",
        };
      });
      errorCount++;
    }

    // Email field
    const email = form.email;
    const emailEmpty = email?.replace(/\s/g, "");
    if (!email || emailEmpty === "") {
      setError((prev) => {
        return {
          ...prev,
          email: "The 'Email' field is required",
        };
      });
      errorCount++;
    }

    // Email field
    const description = form.description;
    const descriptionEmpty = description?.replace(/\s/g, "");
    if (!description || descriptionEmpty === "") {
      setError((prev) => {
        return {
          ...prev,
          description: "The 'Description' field is required",
        };
      });
      errorCount++;
    }

    return errorCount === 0 ? true : false;
  }, []);

  const handleSubmit = React.useCallback(() => {
    setError((prev) => {
      return {
        ...prev,
        submit: null,
      };
    });
    setLoading(true);
    setTimeout(async () => {
      try {
        const isValid = validation(form);

        if (!isValid) {
          setLoading(false);
          return;
        }

        delete form.submit;

        const body = JSON.stringify(form);

        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };

        await API.post("/LabRequests", body, config);

        toast.success("Your Idea Success to Send", {
          duration: 6000,
        });

        handleClose();
        setForm(defaultValue);
        setLoading(false);
      } catch (error) {
        const duration = 2 * 1000;
        setLoading(false);
        const message = error.response.data.message;
        console.error(message);

        setTimeout(() => {
          setError((prev) => {
            return {
              ...prev,
              submit: message,
            };
          });
        }, duration);

        toast.error(message, {
          duration: duration,
        });
      }
    }, 1000);
  }, [form, handleClose, validation]);

  const handleKeyEnter = React.useCallback(
    (event) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  React.useEffect(() => {
    return () => {
      setForm(defaultValue);
    };
  }, []);

  return {
    form,
    handleChange,
    handleKeyEnter,
    handleSubmit,
    loading,
    error,
  };
};
