import React from "react";

import Loading from "../components/Loading";

export default function LoadingPage() {
  return (
    <div className="h-screen text-white justify-center items-center flex flex-col bg-gradient-to-r from-indigo-900 from-10% via-sky-900 via-30% to-emerald-900 to-70%">
      <Loading sx={{ width: "100px" }} />
    </div>
  );
}
