import React from "react";

export default function ButtonContact({ Icon, title, url, idx }) {
  const navigateTo = (url) => {
    window.open(url);
  };

  return (
    <div
      data-aos-duration={`${idx + 10}00`}
      data-aos="flip-left"
      onClick={() => navigateTo(url)}
      className="bg-transparent hover:bg-indigo-500 border border-indigo-500 text-white px-2 py-1 mr-2 mt-2 cursor-pointer flex items-center rounded"
    >
      <Icon size="20" className="mr-1" /> {title}
    </div>
  );
}
