const data = [
  {
    title: "Software Developer",
    workingStart: "2023-07-01",
    workingEnd: null,
    company: "PT. Mejik Utama Sugiharta",
    place: "Jakarta Selatan, Indonesia",
    desc: null,
    skills: "Go-Lang, Dart, Flutter, Typescript",
  },
  {
    title: "Outsource talent Backend Developer",
    workingStart: "2023-05-15",
    workingEnd: "2023-07-14",
    company: "Gabung.in",
    desc: "Building API for UMKM Platform",
    skills: "Laravel, API, Microservices",
  },
  {
    title: "Outsource talent Backend Developer",
    workingStart: "2023-05-29",
    workingEnd: "2023-06-28",
    company: "Inilah.com",
    desc: "Building headless CMS using Strapi",
    skills: "Strapi, NodeJs",
  },
  // {
  //   title: "FullStack Developer",
  //   workingStart: "2023-05-01",
  //   workingEnd: "2023-05-30",
  //   company: "Jobfornesia",
  //   skills:
  //     "NextJs, React Native, ExpressJs, Laravel, CodeIgniter, Golang, Rest API, API Integration, Bootstrap, Material UI, TailwindCss, Dart, Flutter, Deployment, VPS, GIT, Github",
  // },
  {
    title: "Frontend Web Developer",
    workingStart: "2022-12-01",
    workingEnd: "2023-05-30",
    company: "Kontenbase",
    place: "Depok, Jawa Barat, Indonesia",
    desc: "Building Software as a Service (SaaS) for Automate data and Workflow with No Code Platform",
    skills: "Next.js, TypeScript, Zustand",
  },
  {
    title: "Software Developer",
    workingStart: "2022-12-01",
    workingEnd: "2023-05-30",
    company: "Cybermantra",
    place: "Jakarta, Indonesia",
    desc: null,
    skills: "Next.js, TypeScript",
  },
  {
    title: "Mentor Full-Stack Developer",
    workingStart: "2021-04-01",
    workingEnd: "2023-01-01",
    company: "DumbWays Indonesia",
    place: "Tangerang, Banten, Indonesia",
    desc: "Mentoring student, latest technology research, and make syllabus",
    skills:
      "NodeJs, ExpressJs, ReactJs, React Native, Golang, Rest API, API Integration, Bootstrap, Dart, Flutter, Deployment, VPS",
  },
  {
    title: "Frontend Web Developer",
    workingStart: "2020-11-01",
    workingEnd: "2021-03-01",
    company: "Rasyiidu Education Indonesia",
    place: "Special Region of Yogyakarta, Indonesia",
    desc: "Building Rasyiidu Landing, Admin page, & Tryout UTBK platform Test (sobatUTBK)",
    skills: "PHP, CodeIgniter, Boostrap, Jquery",
  },
];

export default data;
