import * as React from "react";
import { Box, Modal, Typography, CircularProgress, Stack } from "@mui/material";
import { ImLab } from "react-icons/im";

import { useRequestLabs } from "../../../hooks";

export function ModalRequestLabs({ open, handleClose }) {
  return (
    <ModalContainer open={open} handleClose={handleClose}>
      <Header />
      <Form handleClose={handleClose} />
    </ModalContainer>
  );
}

const Form = ({ handleClose }) => {
  const { form, handleChange, handleKeyEnter, handleSubmit, loading, error } =
    useRequestLabs({ handleClose });

  return (
    <Box className="mt-6">
      <Box className="flex flex-col gap-y-3">
        {error.submit && (
          <div className="text-red-500 text-sm italic text-center transition-all duration-300 cursor-default bg-red-900/10 hover:bg-red-900/30 w-fit mx-auto px-2 py-1 rounded">
            {error.submit}
          </div>
        )}
        <TextField
          name="name"
          label="Name"
          placeholder="Input your Name ..."
          handleChange={handleChange}
          value={form.name}
          handleKeyEnter={handleKeyEnter}
          error={error.name}
          required
        />
        <TextField
          name="email"
          label="Email"
          placeholder="Input your Email ..."
          handleChange={handleChange}
          value={form.email}
          handleKeyEnter={handleKeyEnter}
          error={error.email}
          required
        />
        <TextArea
          name="description"
          label="Descript idea"
          placeholder="Input your idea ..."
          handleChange={handleChange}
          value={form.description}
          handleKeyEnter={handleKeyEnter}
          error={error.description}
          required
        />
      </Box>
      <Stack direction="row" className="mt-10" columnGap={1}>
        <ButtonClose loading={loading} handleClose={handleClose} />
        <Button loading={loading} handleSubmit={handleSubmit} label="Send" />
      </Stack>
    </Box>
  );
};

const TextField = ({
  name,
  label,
  placeholder,
  value,
  handleChange,
  handleKeyEnter,
  required,
  error,
}) => {
  return (
    <div>
      <h6 className="text-blue-400">
        {label}
        {required && <span className="text-red-500 ml-[2px]">*</span>}
      </h6>
      <input
        className="w-full outline-none py-3 px-4 rounded-2xl text-white placeholder:text-blue-800 bg-blue-900/40 border border-blue-900"
        name={name}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={handleChange}
        onKeyDown={handleKeyEnter}
      />
      <div className="text-red-500 text-sm italic">{error}</div>
    </div>
  );
};

const TextArea = ({
  name,
  label,
  placeholder,
  value,
  handleChange,
  handleKeyEnter,
  required,
  rows = 5,
  error,
}) => {
  return (
    <div>
      <h6 className="text-blue-400">
        {label}
        {required && <span className="text-red-500 ml-[2px]">*</span>}
      </h6>
      <textarea
        className="w-full outline-none py-3 px-4 rounded-2xl text-white placeholder:text-blue-800 bg-blue-900/40 border border-blue-900"
        name={name}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={handleChange}
        onKeyDown={handleKeyEnter}
        rows={rows}
      />
      <div className="text-red-500 text-sm italic">{error}</div>
    </div>
  );
};

const ButtonClose = ({ loading, handleClose }) => {
  return (
    <button
      className="transition-all duration-700 font-bold border-4 border-red-500/5 bg-red-500/20 hover:bg-red-600 text-red-600 hover:text-red-100 px-4 rounded-xl"
      disabled={loading}
      onClick={handleClose}
    >
      X
    </button>
  );
};

const Button = ({ loading, label, handleSubmit, className }) => {
  return (
    <button
      disabled={loading}
      className={`${className} transition-all duration-700 border-4 border-yellow-400/5 text-yellow-200 hover:text-black bg-yellow-500/50 hover:bg-yellow-400 active:bg-yellow-700 w-full rounded-xl text-xl py-1 font-bold ${
        loading &&
        "bg-gray-500 hover:bg-gray-500 active:bg-gray-500 pb-0 !pt-2 cursor-progress"
      }`}
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={20} className="!text-white" /> : label}
    </button>
  );
};

const ModalContainer = ({ children, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          transform: "translate(-50%, -50%)",
        }}
        className="absolute top-1/2 left-1/2 px-3 py-2 w-full sm:w-[600px] outline-none"
      >
        <Box
          sx={{
            boxShadow: "0 0 30px rgba(0, 0, 255, 0.3)",
          }}
          className="text-white rounded-3xl py-8 sm:px-8 px-5 bg-slate-800/70 backdrop-blur-[6px] outline-none border-2 border-dashed border-blue-900"
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

const Header = () => {
  return (
    <Typography
      id="modal-modal-title"
      variant="h5"
      component="h3"
      className="[text-shadow:_5px_5px_5px_rgb(0_0_0_/_50%)] flex justify-center flex-col sm:flex-row gap-x-1 sm:gap-x-3"
    >
      Request Your idea
      <span className="flex gap-x-2">
        for
        <span className="text-yellow-400 flex gap-x-1">
          <ImLab /> My Labs
        </span>
      </span>
    </Typography>
  );
};
