import * as React from "react";
import { ImLab } from "react-icons/im";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";

import Title from "../Title";
import CardPortfolio from "./micro/cardPortfolio";
import CardPortfolioGithub from "./micro/cardPortfolioGithub";
import ShowAll from "./../ShowAll";
import ToggleWrapper from "../ToggleWrapper";
import { useDisclose } from "../../hooks";

import dataPortfolio from "../../dummy/portfolio";
import { ModalRequestLabs } from "./micro/ModalRequestLabs";

export default function Portfolio({ defaultShow }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isOpen, onToggle } = useDisclose(defaultShow);
  const [isExpand, setExpand] = React.useState(false);

  const data = React.useMemo(() => {
    if (!isExpand) {
      return dataPortfolio.filter((_, idx) => idx < 3);
    }

    return dataPortfolio;
  }, [isExpand]);

  return (
    <div>
      <Title
        icon={<ImLab />}
        label="My Labs"
        onClick={onToggle}
        button={
          <Tooltip
            title="Request your idea"
            arrow
            TransitionComponent={Zoom}
            followCursor
          >
            <button
              onClick={handleOpen}
              className="bg-yellow-400 hover:bg-yellow-500 text-black px-2 rounded font-bold border-2 border-yellow-400 hover:border-white transition-all duration-300"
            >
              Req
            </button>
          </Tooltip>
        }
      />
      <ModalRequestLabs open={open} handleClose={handleClose} />
      <ToggleWrapper show={isOpen}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
          {data.map((data, idx) => (
            <CardPortfolio data={data} key={idx} idx={idx} />
          ))}
          <CardPortfolioGithub />
        </div>
        <ShowAll isExpand={isExpand} setExpand={setExpand} title="Portfolio" />
      </ToggleWrapper>
    </div>
  );
}
