import * as React from "react";

import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { API } from "../apis/microgen";

import Card from "./Card";
import Title from "./Title";
import ToggleWrapper from "./ToggleWrapper";
import { useDisclose } from "../hooks";

const CommitList = ({ repoInfos }) => {
  return (
    <div className="bg-slate-800 p-4 rounded-md space-y-2">
      {repoInfos.map((data, idx) => (
        <div
          key={idx}
          className="relative text-left border border-blue-900 px-4 py-5 text-white rounded hover:bg-slate-900"
        >
          <div className="text-sm">{data?.commit?.message}</div>
          <div className="text-sm mt-2 text-slate-400 flex">
            <a
              href={data?.committer?.html_url}
              target="_blank"
              className="flex text-white hover:underline"
              rel="noreferrer"
            >
              <img
                src={data?.author?.avatar_url}
                alt={data?.author?.login}
                className="rounded-full mr-2 bg-black"
                style={{ width: 20, height: 20 }}
              />
              {data?.author?.login}
            </a>
            <div className="ml-1">
              committed {moment(data?.commit?.committer?.date).fromNow()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default function LatestUpdate({ defaultShow }) {
  const { isOpen, onToggle } = useDisclose(defaultShow);
  const [currentTime, setCurrentTime] = React.useState("");
  const [repoInfo, setRepoInfo] = React.useState(null);
  const [repoInfos, setRepoInfos] = React.useState(null);
  const [visitorCount, setVisitorCount] = React.useState(0);

  const latesupdatedDate = React.useMemo(() => {
    if (!repoInfo) return null;

    return repoInfo?.commit?.committer?.date;
  }, [repoInfo]);

  const latesUpdated = React.useMemo(() => {
    if (!latesupdatedDate) return null;

    return moment(latesupdatedDate).fromNow();
  }, [latesupdatedDate]);

  const getRepoInfo = React.useCallback(async () => {
    try {
      const authToken = process.env.REACT_APP_GITHUB_TOKEN;
      const url =
        "https://api.github.com/repos/Jody-septiawan/portfolio/commits";

      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `token ${authToken}`,
        },
      });

      const data = await res.json();

      setRepoInfos(data);
      setRepoInfo(data[0]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleShowAlert = React.useCallback(() => {
    const MySwal = withReactContent(Swal);

    const count = repoInfos?.length;

    MySwal.fire({
      title: <div className="text-white">{count} latest commits</div>,
      html: <CommitList repoInfos={repoInfos} />,
      icon: "info",
      background: "#1e293b",
      backdrop: "rgba(0, 0, 0, 0.9)",
    });
  }, [repoInfos]);

  const getVisitorCount = React.useCallback(async () => {
    try {
      const response = await API.get("/Visitors/count");

      const count = response.data.count;

      setVisitorCount(count);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getRepoInfo();
    getVisitorCount();
  }, [getRepoInfo, getVisitorCount]);

  React.useEffect(() => {
    setInterval(() => {
      setCurrentTime(moment().format("D MMMM YYYY, h:mm:ss a"));
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col gap-4 sticky top-0">
      <Title label="Latest Update" onClick={onToggle} />
      <ToggleWrapper show={isOpen}>
        <Card>
          <div className="flex flex-col gap-4">
            <div className="flex">
              <span className="text-slate-600 mr-2 text-sm">
                Visitor Count:{" "}
              </span>{" "}
              {visitorCount}
            </div>

            <div>
              <span className="text-slate-600 mr-2 text-sm">
                Latest update:
              </span>
              <div>
                <span className="text-slate-600 text-sm">- at </span>
                {latesUpdated}
              </div>
              <div className="flex">
                <span className="text-slate-600 text-sm">- by</span>
                <img
                  src={repoInfo?.author?.avatar_url}
                  alt={repoInfo?.author?.login}
                  className="rounded-full mr-2 bg-black ml-2"
                  style={{ width: 20, height: 20 }}
                />
                <a
                  href={repoInfo?.author?.html_url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-white hover:underline"
                >
                  {repoInfo?.author?.login}
                </a>
              </div>
              <div>
                <span className="text-slate-600 mr-1 text-sm">- message </span>
                <span className="cursor-pointer" onClick={handleShowAlert}>
                  {repoInfo?.commit?.message}
                </span>
              </div>
            </div>

            <div>
              <span className="text-slate-600 mr-2 text-sm">
                Current time:{" "}
              </span>{" "}
              {currentTime}{" "}
            </div>
          </div>
        </Card>
      </ToggleWrapper>
    </div>
  );
}
