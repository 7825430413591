import React from "react";

import Contact from "../left-side/Contact";
import ContactBottom from "../left-side/ContactBottom";
import Profile from "../left-side/Profile";
import Blog from "../right-side/Blog";
import Stack from "../left-side/Stack";

export default function LeftSide() {
  return (
    <div className="flex flex-col gap-y-10">
      <Profile />
      <Contact />
      <Stack defaultShow={true} />
      <Blog />
      <ContactBottom />
    </div>
  );
}
