import * as React from "react";

import contact from "../../dummy/contact";

import ButtonContact from "./micro/ButtonContact";
import Title from "../Title";

export default function Contact() {
  return (
    <>
      <div className="md:block hidden">
        <Title label="Contact" />
        <div className="flex flex-wrap mt-2">
          {contact.map((data, idx) => (
            <ButtonContact
              key={idx}
              idx={idx}
              Icon={data.icon}
              title={data.title}
              url={data.url}
            />
          ))}
        </div>
      </div>
    </>
  );
}
