import * as React from "react";

import LeftSide from "../components/main-side/LeftSide";
import RightSide from "../components/main-side/RightSide";

export default function Main() {
  return (
    <div className="min-h-screen py-4 md:py-8 xl:container">
      <div className="text-white grid grid-cols-1 lg:grid-cols-12 gap-8 mb-20 md:mb-10">
        <div className="col-span-12 lg:col-span-8 px-2 lg:px-0">
          <LeftSide />
        </div>
        <div className="col-span-12 lg:col-span-4 px-2 lg:px-0">
          <RightSide />
        </div>
      </div>
    </div>
  );
}
