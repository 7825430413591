import moment from "moment";
import * as React from "react";
import { HiDocumentText } from "react-icons/hi";

export default function EducationItem({ data }) {
  const start = React.useMemo(() => {
    if (!data.timeStart) return;

    const check = moment(data.timeStart, "YYYY/MM/DD");

    return `${check.format("YYYY")}`;
  }, [data.timeStart]);

  const end = React.useMemo(() => {
    if (!data.timeEnd) return;

    const check = moment(data.timeEnd, "YYYY/MM/DD");

    return `${check.format("YYYY")}`;
  }, [data.timeEnd]);

  return (
    <li className="mb-4 ml-4 transition-all">
      <div
        data-aos="fade-up-right"
        className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <div data-aos="flip-up">
        <h3
          className="text-lg font-semibold text-gray-900 dark:text-white mb-0 hover:text-blue-500 cursor-pointer"
          onClick={() => {
            if (!data.url) return;
            window.open(data.url, "_blank");
          }}
        >
          {data.title}
        </h3>
        <time className="mb-1 mt-0 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          {start} {start && "-"} {end}
        </time>
        <p className="mb-2 text-base font-normal text-gray-500 dark:text-gray-400">
          {data.subtitle}
        </p>
        <div className="flex">
          {data.docs &&
            data.docs.map((item, idx) => (
              <HiDocumentText
                key={idx}
                size="20"
                className="cursor-pointer hover:text-lime-500"
                onClick={() => window.open(item.url)}
              />
            ))}
        </div>
      </div>
    </li>
  );
}
