import * as React from "react";

// import UnderCostruction from "../UnderCostruction";
import TimelineExperienceItem from "./micro/timelineExperienceItem";
import ShowAll from "./../ShowAll";

import dataExperience from "../../dummy/experience";
import Title from "../Title";
import ToggleWrapper from "../ToggleWrapper";
import { useDisclose } from "../../hooks";

export default function Experience({ defaultShow }) {
  const { isOpen, onToggle } = useDisclose(defaultShow);
  const [isExpand, setExpand] = React.useState(false);

  const data = React.useMemo(() => {
    if (!isExpand) {
      return dataExperience.filter((_, idx) => idx < 2);
    }

    return dataExperience;
  }, [isExpand]);

  return (
    <div>
      <Title label="Experience" onClick={onToggle} />
      <ToggleWrapper show={isOpen}>
        <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3 mt-4">
          {data.map((data, idx) => (
            <TimelineExperienceItem data={data} key={idx} />
          ))}
        </ol>
        <ShowAll isExpand={isExpand} setExpand={setExpand} title="Experience" />
      </ToggleWrapper>
    </div>
  );
}
