import moment from "moment";
import * as React from "react";

export default function TimelineExperienceItem({ data }) {
  const activeWork = React.useMemo(() => {
    const now = moment(data.workingEnd ?? new Date()); //todays date
    const end = moment(data.workingStart); // another date
    const duration = moment.duration(now.diff(end));
    const month = duration.asMonths();
    const year = duration.asYears() >= 1 ? duration.asYears() : "";
    return `${year && Math.floor(year) + " year"} ${
      month && (Math.ceil(month) % 12) + " month"
    }`;
  }, [data.workingEnd, data.workingStart]);

  const start = React.useMemo(() => {
    if (!data.workingStart) return;

    const check = moment(data.workingStart, "YYYY/MM/DD");

    return `${check.format("MMMM")} ${check.format("YYYY")}`;
  }, [data.workingStart]);

  const end = React.useMemo(() => {
    if (!data.workingEnd) return "Present";

    const check = moment(data.workingEnd, "YYYY/MM/DD");

    return `${check.format("MMMM")} ${check.format("YYYY")}`;
  }, [data.workingEnd]);

  return (
    <li className="mb-10 ml-6">
      <span
        data-aos="zoom-in"
        className={
          "flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white " +
          (!data.workingEnd
            ? "dark:ring-gray-900 dark:bg-blue-900"
            : "dark:ring-gray-900 dark:bg-gray-800")
        }
      >
        <svg
          className={
            "w-3 h-3 " + (!data.workingEnd ? "text-white" : "text-gray-500")
          }
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clipRule="evenodd"
          ></path>
        </svg>
      </span>
      <div data-aos="flip-up">
        <h3
          className={
            "mb-1 text-lg font-semibold " + (data.workingEnd && "text-gray-500")
          }
        >
          {data.title}
        </h3>
        <p className="text-base mb-1 font-normal text-gray-500 dark:text-gray-400">
          <i>{data.company}</i>
        </p>
        <p className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          {start} {start ? "-" : ""} {end} ·{" "}
          <span className="text-gray-400">{activeWork}</span>
        </p>
        <p className="block mb-4 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          {data.place}
        </p>
        <p className="text-sm mb-1 font-normal text-gray-500 dark:text-gray-400">
          {data.desc}
        </p>
        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
          <b>Skills</b>: {data.skills}
        </p>
      </div>
    </li>
  );
}
