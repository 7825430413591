import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { RiTelegramFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";

const data = [
  // {
  //   icon: AiFillGithub,
  //   title: 'Github',
  //   url: 'https://github.com/Jody-septiawan',
  // },
  {
    icon: AiFillLinkedin,
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/jodyseptiawan/",
  },
  {
    icon: AiFillInstagram,
    title: "Instagram",
    url: "https://www.instagram.com/jedeye__/",
  },
  // {
  //   icon: RiWhatsappFill,
  //   title: "Whatsapp",
  //   url: "https://wa.me/6285155188619",
  // },
  {
    icon: RiTelegramFill,
    title: "Telegram",
    url: "https://t.me/JodySeptiawan",
  },
  // {
  //   icon: HiDocumentText,
  //   title: "PDF",
  //   url: "https://drive.google.com/file/d/1Llp7PRrCtiyWiDWPT4oa_5v3DmLS1Bzp/view?usp=sharing",
  // },
  {
    icon: MdEmail,
    title: "Email",
    url: "mailto:	me@jedeye.dev",
  },
];

export default data;
