import React from "react";
import { useDetectAdBlock } from "adblock-detect-react";

import Loading from "../Loading";
import { API } from "../../apis/microgen";
import Title from "../Title";
import Card from "../Card";
import ToggleWrapper from "../ToggleWrapper";
import { useDisclose } from "../../hooks";

export default function YourInformation({ defaultShow }) {
  const { isOpen, onToggle } = useDisclose(defaultShow);
  const adBlockDetected = useDetectAdBlock();

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [ipify, setIpify] = React.useState(null);

  const convertIpApiData = React.useCallback((newData) => {
    const {
      ip,
      network,
      version,
      city,
      region,
      country,
      postal,
      longitude,
      latitude,
      timezone,
      currency,
      languages,
      asn,
      org,
    } = newData;

    return {
      ip,
      network,
      version,
      city,
      region,
      country,
      postal,
      longitude,
      latitude,
      timezone,
      currency,
      languages,
      asn,
      org,
      countryName: newData?.country_name,
      countryCapital: newData?.country_capital,
      utcOffset: newData?.utc_offset,
      countryCallingCode: newData?.country_calling_code,
      currencyName: newData?.currency_name,
      countryArea: newData?.country_area,
      countryPopulation: newData?.country_population,
    };
  }, []);

  const addVisitor = React.useCallback(async (visitorData) => {
    setIsLoading(true);
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const dataIp = await response.json();
      const ipifyData = dataIp.ip;
      setIpify(ipifyData);

      let dataBody = { ...visitorData, ip: ipifyData };

      const body = JSON.stringify(dataBody);

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      await API.post("/Visitors", body, config);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  }, []);

  const getData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://ipapi.co/json");
      const jsonData = await response.json();

      const ipData = convertIpApiData(jsonData);

      setData(ipData);

      addVisitor(ipData);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [addVisitor, convertIpApiData]);

  React.useEffect(() => {
    if (adBlockDetected) {
      alert("ad block detected");
    }
    getData();
  }, [adBlockDetected, getData]);

  const dataList = React.useMemo(() => {
    return [
      {
        label: "IP Address",
        value: ipify ?? data?.ip,
      },
      {
        label: "ORG",
        value: data?.org,
      },
      {
        label: "Longitude",
        value: data?.longitude,
      },
      {
        label: "Latitude",
        value: data?.latitude,
      },
      {
        label: "Country",
        value: data?.country + " - " + data?.countryName,
      },
      {
        label: "Timezone",
        value: data?.timezone,
      },
      {
        label: "Region",
        value: data?.region,
      },
      {
        label: "Currency",
        value: data?.currency + " - " + data?.currencyName,
      },
      {
        label: "Postal",
        value: data?.postal,
      },
      {
        label: "Languages",
        value: data?.languages,
      },
      {
        label: "Country Area",
        value: data?.countryArea,
      },
      {
        label: "Country Population",
        value: data?.countryPopulation,
      },
      {
        label: "Country Calling Code",
        value: data?.countryCallingCode,
      },
    ];
  }, [
    data?.country,
    data?.countryArea,
    data?.countryCallingCode,
    data?.countryName,
    data?.countryPopulation,
    data?.currency,
    data?.currencyName,
    data?.ip,
    data?.languages,
    data?.latitude,
    data?.longitude,
    data?.org,
    data?.postal,
    data?.region,
    data?.timezone,
    ipify,
  ]);

  return (
    <div className="flex flex-col gap-4">
      <Title label="Your Information" onClick={onToggle} />
      <ToggleWrapper show={isOpen}>
        <Card>
          {isLoading && (
            <div>
              <Loading sx={{ width: "100px", marginTop: "50px" }} />
            </div>
          )}
          {!isLoading && (data || ipify) && (
            <div className="grid grid-cols-2 gap-4">
              {dataList.map(
                (item) =>
                  item.value && (
                    <div key={item.label} className="col-span-2 md:col-span-1">
                      <h3 className="text-xs text-gray-500 mb-1">
                        {item.label}
                      </h3>
                      <p>{item.value}</p>
                    </div>
                  )
              )}
            </div>
          )}
        </Card>
      </ToggleWrapper>
    </div>
  );
}
