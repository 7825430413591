import Portfolio from "../right-side/Portfolio";
import Education from "../left-side/Education";
import YourInformation from "../left-side/YourInformation";
import Experience from "../right-side/Experience";
import LatestUpdate from "../LatestUpdate";

export default function RightSide() {
  return (
    <div className="flex flex-col gap-y-5 h-full">
      <Portfolio defaultShow={true} />
      <Education defaultShow={false} />
      <Experience defaultShow={false} />
      <YourInformation defaultShow={false} />
      <LatestUpdate defaultShow={true} />
    </div>
  );
}
