import * as React from "react";

import Title from "../Title";
// import Card from "../Card";
import CardBlog from "./micro/CardBlog";

export default function Blog() {
  const [data, setData] = React.useState([]);

  const getData = React.useCallback(async () => {
    try {
      const response = await fetch(
        // "https://dev.to/api/articles?username=nataliedeweerd"
        "https://dev.to/api/articles?username=jodyseptiawan"
      );

      const res = await response.json();

      setData(res);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="flex flex-col gap-4">
      <Title label="Blog" />
      <div className="flex flex-col gap-2 px-1">
        {data.map((item) => (
          <CardBlog key={item.title} item={item} />
        ))}
      </div>
    </div>
  );
}
