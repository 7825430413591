import React from "react";

import contact from "../../dummy/contact";

// import ButtonContact from './micro/ButtonContact';

export default function ContactBottom() {
  return (
    <div
      className="block md:hidden bg-gray-900 border border-blue-900 fixed left-0 right-0 flex justify-around py-2 px-6"
      style={{
        zIndex: 999,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        bottom: -1,
      }}
    >
      {contact.map((data, idx) => (
        <data.icon size="30" key={idx} onClick={() => window.open(data.url)} />
      ))}
    </div>
  );
}
