import * as React from "react";

export default React.memo(function Title(props) {
  const { icon, onClick, label, button } = props;
  return (
    <div className="flex" data-aos="fade-right">
      <div
        className={`text-2xl border-b-2 border-[#397ff0] w-fit ${
          onClick && "cursor-pointer hover:text-[#397ff0]"
        }`}
        onClick={onClick}
      >
        <div className="flex gap-x-2">
          {icon}
          {label}
        </div>
      </div>
      <div className="flex-1 border-b-2 border-[#212e4a] flex justify-end items-center">
        {button}
      </div>
    </div>
  );
});
