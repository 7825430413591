import { AiFillGithub } from "react-icons/ai";

export default function CardPortfolioGithub({ idx }) {
  return (
    <div
      className="transition-all border-2 rounded border-dashed border-blue-900 bg-slate-900 hover:bg-zinc-800 hover:border-white p-2 flex justify-center items-center"
      data-aos="fade-up"
      data-aos-delay={`${idx}00`}
    >
      More on{" "}
      <span
        className="flex text-blue-500 hover:text-white cursor-pointer"
        onClick={() =>
          window.open("https://github.com/Jody-septiawan?tab=repositories")
        }
      >
        <AiFillGithub size="23" className="ml-3 mr-1" /> Github
      </span>
    </div>
  );
}
