import SuperMario from "../assets/img/super-mario.svg";

const data = {
  name: "Jody Septiawan",
  img: "https://avatars.githubusercontent.com/u/44697757",
  hover: {
    img: SuperMario,
  },
  desc: `Software Developer & AI enthusiast`,
  // flag: "😁",
};

export default data;
