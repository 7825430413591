import * as React from "react";

export default function ShowAll(props) {
  const { isExpand, setExpand, title } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const onExpand = React.useCallback(() => {
    setIsLoading(true);

    setTimeout(() => {
      setExpand(!isExpand);
    }, 500);
  }, [isExpand, setExpand]);

  if (!isExpand) {
    return (
      <div
        className="opacity-30 hover:opacity-80 transition-opacity border-2 border-stone-700 hover:border-stone-600 bg-slate-800 text-center p-1 rounded cursor-pointer text-stone-400 hover:text-stone-100"
        onClick={onExpand}
      >
        {isExpand
          ? "Hide"
          : isLoading
          ? "Loading..."
          : `Show all ${title ?? ""}`}
      </div>
    );
  }
}
