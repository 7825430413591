import React from "react";

import dataStack from "../../dummy/stack";
import Title from "../Title";
import ToggleWrapper from "../ToggleWrapper";
import { useDisclose } from "../../hooks";

export default function Stack({ defaultShow }) {
  const { isOpen, onToggle } = useDisclose(defaultShow);

  const navigateTo = (url) => {
    window.open(url);
  };

  return (
    <div>
      <Title label="Programming Stack" onClick={onToggle} />
      <ToggleWrapper show={isOpen}>
        <div className="flex flex-wrap mt-4 gap-1">
          {dataStack.map((data, idx) => (
            <img
              data-aos="fade-up"
              data-aos-delay={`${idx + 1}00`}
              key={idx}
              src={data.img}
              alt=""
              className="w-18 h-10 rounded cursor-pointer bg-white p-1 hover:bg-indigo-900 hover:border-2 hover:border-indigo-500"
              onClick={() => navigateTo(data.url)}
            />
          ))}
        </div>
      </ToggleWrapper>
    </div>
  );
}
