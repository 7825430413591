import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Toaster } from "react-hot-toast";

import Main from "./pages/Main";
// import Cycle from "./pages/Cycle";
// import Todo from "./pages/Todo";
import NotFound from "./pages/NotFound";
import LoadingPage from "./pages/Loading";
import { API, setAuthToken } from "./apis/microgen";

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLogin = React.useCallback(async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const body = JSON.stringify({
        email: process.env.REACT_APP_MICROGEN_EMAIL,
        password: process.env.REACT_APP_MICROGEN_PASSWORD,
      });

      const response = await API.post("/auth/login", body, config);

      const { token } = response.data;

      setAuthToken(token);

      localStorage.setItem("token", token);
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", { message });
    }
  }, []);

  React.useEffect(() => {
    onLogin();
    Aos.init({ duration: 1000 });
  }, [onLogin]);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  React.useEffect(() => {
    const hostname = window.location.hostname;
    const desc = "Software Developer & AI enthusiast";
    document.title = `Jody Septiawan |  ${hostname} | ${desc}`;
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/cycle" element={<Cycle />} />
        <Route path="/cycle/:id" element={<Todo />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
}

export default App;
