import * as React from "react";

import TimelineEducationItem from "./micro/EducationItem";
import ShowAll from "./../ShowAll";

import dataEducation from "../../dummy/education";
import Title from "../Title";
import ToggleWrapper from "../ToggleWrapper";
import { useDisclose } from "../../hooks";

export default function Education({ defaultShow }) {
  const { isOpen, onToggle } = useDisclose(defaultShow);
  const [isExpand, setExpand] = React.useState();

  const data = React.useMemo(() => {
    if (!isExpand) {
      return dataEducation.filter((_, idx) => idx < 2);
    }

    return dataEducation;
  }, [isExpand]);

  return (
    <div>
      <Title label="Education" onClick={onToggle} />
      <ToggleWrapper show={isOpen}>
        <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-2 mt-4">
          {data.map((data, idx) => (
            <TimelineEducationItem data={data} key={idx} />
          ))}
        </ol>
        <ShowAll isExpand={isExpand} setExpand={setExpand} title="Education" />
      </ToggleWrapper>
    </div>
  );
}
