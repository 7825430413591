import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="h-screen bg-cyan-900 text-white justify-center flex items-center font-mono text-3xl">
      Salah alamat,
      <Link to="/" className="text-sky-400 hover:text-green-400">
        Balik
      </Link>
    </div>
  );
}
